import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import Link from 'shopper/components/Link';
import Text from 'shopper/components/Text';

import placeholder from 'lib/placeholder';

const TextWithEllipsisWithShowMore = ({
  children,
  className,
  initialShowSeeMoreLinkState = false,
  lineClampClassName,
  openedStateClassName,
  showMoreProps,
  size,
  ...rest
}) => {
  const [isOpen, setOpen] = useState(false);
  const styledTextWithEllipsisRef = useRef(null);
  const [showSeeMoreLink, setShowSeeMoreLink] = useState(
    initialShowSeeMoreLinkState
  );

  const onSeeMoreLinkButtonClick = () => {
    if (isOpen) {
      styledTextWithEllipsisRef.current.scrollTop = 0;
    }

    setOpen(() => !isOpen);
  };

  useEffect(() => {
    if (initialShowSeeMoreLinkState === null) {
      setShowSeeMoreLink(
        () =>
          isOpen ||
          styledTextWithEllipsisRef.current?.clientHeight <
            styledTextWithEllipsisRef.current?.scrollHeight
      );
    }
  }, [isOpen, initialShowSeeMoreLinkState]);

  const classNameIncludesMaxHeight = className?.includes('max-h');

  return (
    <div className="relative w-fit">
      <Text
        ref={styledTextWithEllipsisRef}
        className={twMerge(
          '[&>img]:max-w-full',
          isOpen ? 'overflow-y-scroll' : 'line-clamp-1 overflow-y-hidden',
          isOpen && openedStateClassName,
          !isOpen && lineClampClassName,
          className
        )}
        size={size}
        {...rest}
      >
        {children}
      </Text>
      {showSeeMoreLink && (
        <Link
          /**
           * TODO: update component adjusting your types and removing these
           * custom styles
           */
          className={twJoin(
            'w-fit text-primary-300 dark:text-primary-300',
            classNameIncludesMaxHeight && 'mt-2'
          )}
          size={size}
          onClick={onSeeMoreLinkButtonClick}
          {...showMoreProps}
        >
          {isOpen
            ? placeholder('actions.seeLess')
            : placeholder('actions.seeMore')}
        </Link>
      )}
    </div>
  );
};

TextWithEllipsisWithShowMore.propTypes = {
  initialShowSeeMoreLinkState: PropTypes.bool,
  showMoreProps: PropTypes.shape(),
};

export default TextWithEllipsisWithShowMore;
