import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import Button from 'shopper/components/Button';
import Text from 'shopper/components/Text';

import TextWithEllipsisWithShowMore from 'components/TextWithEllipsisWithShowMore';

import useDialog from 'hooks/useDialog';

import placeholder from 'lib/placeholder';

import { useUser } from 'providers/UserProvider';

import LINKS from 'constants/links';

const LoginDialog = dynamic(() => import('components/LoginDialog'), {
  ssr: false,
});

const COMPLETE_DESCRIPTION = [
  'O Promobit é a maior comunidade de ofertas do Brasil e por aqui o que não faltam são produtos em promoção, já que por dia são mais de 1 mil ofertas compartilhadas por nossa comunidade. Todas elas passam por um criterioso processo de validação que garante que apenas as ofertas de verdade e disponíveis em lojas confiáveis sejam compartilhadas na plataforma.',
  'Porém, a comunidade do Promobit não contribui apenas com o compartilhamento de promoções, já que os membros da comunidade também estão presentes nas ofertas publicadas através de comentários e reviews, proporcionando uma grande troca de informações e experiências com produtos entre os usuários, dando suporte para que os usuários do Promobit façam sua decisão de compras, seja nas ofertas de eletrodomésticos, de itens para home office ou em games.',
  'Aqui você também encontra muitos cupons de desconto para as principais varejistas como Casas Bahia, Americanas e Madeira Madeira. Em nossa página de cupons de desconto você também encontra cupons exclusivos do Promobit e consegue obter aquele desconto que faltava para chegar ao valor que deseja pagar. Basta pegar o cupom na página do Promobit e garantir um preço final menor.',
  'Já a ferramenta de lista de desejos é de grande serventia para informá-lo quando um produto em promoção está disponível. Basta adicionar o produto que deseja comprar e quando ele estiver em oferta, seja um novo fogão, geladeira e demais eletrodomésticos baratos, o Promobit te avisa.',
  'A variedade de ofertas no Promobit é muito grande ao longo de todo o ano, porém, é durante Black Friday que as melhores condições de compras aparecem, seja por cupom de desconto ou com preços bem abaixo da média do mercado. A Black Friday acontece anualmente no último final de semana de novembro e para não perder nenhuma oportunidade de compra durante a Black Friday basta ficar atento ao Promobit e garantir a economia.',
];

const WhatIsPromobitDescription = () => {
  const { showDialog } = useDialog();
  const { pathname } = useRouter();
  const { isLoggedIn } = useUser();

  return (
    <>
      {pathname === LINKS.HOME ? (
        <TextWithEllipsisWithShowMore
          as="p"
          lineClampClassName="line-clamp-[14]"
          openedStateClassName="max-h-[450px]"
        >
          {COMPLETE_DESCRIPTION.map((text, index) => (
            <Text key={index} as="span" size="size2">
              {text}
            </Text>
          ))}
        </TextWithEllipsisWithShowMore>
      ) : (
        <>
          <Text className="mb-4 text-base lg:text-sm lg:leading-6" size="size1">
            {placeholder('descriptions.whatIsPromobit')}
          </Text>
          {!isLoggedIn && (
            <Button
              size="size2"
              type="secondary"
              full
              onClick={() => showDialog(LoginDialog)}
            >
              {placeholder('actions.signupShort')}
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default WhatIsPromobitDescription;
